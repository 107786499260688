import React, { useContext, useState } from 'react';
import Modal from 'react-bootstrap/Modal';
import Button from 'react-bootstrap/Button';
import { CopyToClipboard } from 'react-copy-to-clipboard';
import { Tooltip } from '@mui/material';
import UserContext from 'context/UserContext';
import useLang from 'context/LanguageContext';
import { translate as t } from 'utils/helpers';
import styles from './Popup.module.scss';

const ImportPodcastMessagePopup = () => {
  const { lang } = useLang();
  const [userData, setUserData] = useContext(UserContext);
  const [copied, setCopied] = useState(false);

  const hideModal = () => {
    if (userData?.importPodcast?.closeActionCallback) {
      userData?.importPodcast?.closeActionCallback();
    }
    setUserData((prevState) => ({
      ...prevState,
      importPodcast: { showModal: false },
    }));
  };
  return (
    <Modal
      className={styles.modalParent}
      show={userData?.importPodcast?.showModal}
      size="md"
      aria-labelledby="contained-modal-title-vcenter"
      onHide={hideModal}>
      <Modal.Body className={styles.modalBody}>
        <h4 className={styles.modalTitle}>{userData?.importPodcast?.title}</h4>
        {userData?.importPodcast?.showIcon !== false && (
          <img
            className={styles.modalIcon}
            src="/other-icons/thanks-icon.svg"
            alt="Thanks"
          />
        )}
        <p
          className={styles.modalDescription}
          style={{ whiteSpace: 'pre-line' }}>
          {userData?.importPodcast?.message}
        </p>
        <div className={styles.confirmCTAs}>
          {!userData?.importPodcast?.hideCancelButton && (
            <Button
              className={`${styles.popupBtn} compact mb-3`}
              variant="outline-primary"
              onClick={hideModal}>
              {userData?.importPodcast?.cancelButton ? userData?.importPodcast?.cancelButton : t(lang, 'common')('close')}
            </Button>
          )}
          {userData?.importPodcast?.showSubmitButton && (
            <Button
              className={`${styles.popupBtn} compact mb-3`}
              onClick={() => {
                setUserData((prevState) => ({
                  ...prevState,
                  importPodcast: { showModal: false },
                }));
                userData?.importPodcast?.confirmActionCallback();
              }}
              style={{
                backgroundColor: userData?.importPodcast?.submitButtonColor,
                color: userData?.importPodcast?.submitButtonColor && '#fff',
                borderColor: userData?.importPodcast?.submitButtonColor,
              }}>
              {userData?.importPodcast?.submitButton}
            </Button>
          )}
          {userData?.importPodcast?.copyToClipboardButton && (
            <CopyToClipboard
              text={userData?.importPodcast?.copyToClipboardButtonUrl}
              onCopy={() => setCopied(true)}>
              <Tooltip
                open={copied}
                onClose={() => setCopied(false)}
                title={t(lang, 'podcatcher')('copied')}
                disableFocusListener
                arrow>
                <Button
                  id="podcatcherCopyButton"
                  type="button"
                  className={`${styles.popupBtn} compact mb-3`}>
                  {userData?.importPodcast?.copyToClipboardButton}
                </Button>
              </Tooltip>
            </CopyToClipboard>
          )}
        </div>
      </Modal.Body>
    </Modal>
  );
};

export default ImportPodcastMessagePopup;
