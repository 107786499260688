import { createContext, useState, useContext, useEffect } from "react";
import { useRouter } from "next/router";
import PropTypes from "prop-types";

const LoadingContext = createContext();

export const LoadingProvider = ({ children }) => {
  const [isPageLoaded, setIsPageLoaded] = useState(true);
  const router = useRouter();

  useEffect(() => {
    const handleRouteChangeStart = () => setIsPageLoaded(false);
    const handleRouteChangeComplete = () => setIsPageLoaded(true);

    router.events.on("routeChangeStart", handleRouteChangeStart);
    router.events.on("routeChangeComplete", handleRouteChangeComplete);
    router.events.on("routeChangeError", handleRouteChangeComplete);

    // Cleanup event listeners on unmount
    return () => {
      router.events.off("routeChangeStart", handleRouteChangeStart);
      router.events.off("routeChangeComplete", handleRouteChangeComplete);
      router.events.off("routeChangeError", handleRouteChangeComplete);
    };
  }, [router]);

  return (
    <LoadingContext.Provider value={{ isPageLoaded }}>
      {children}
    </LoadingContext.Provider>
  );
};

LoadingProvider.propTypes = {
  children: PropTypes.node.isRequired,
};

export const useLoading = () => useContext(LoadingContext);
