import React, { useEffect } from 'react';
import App from 'next/app';
import Router from 'next/router';
import PropTypes from 'prop-types';
import NProgress from 'nprogress';
import { toast } from 'react-toastify';
import cookies from 'lib/next-cookies';
import Cookies from 'lib/js-cookie';
import PodcastPlayer from 'components/PodcastPlayer';
import UserProvider from 'provider/UserProvider';
import Toast from 'components/Toast';
import SuccessMessagePopup from 'components/Toast/SuccessMessagePopup';
import ImportPodcastMessagePopup from 'components/Toast/ImportPodcastMessagePopup';
import CountrySelectionPopup from 'components/Toast/CountrySelectionPopup';
import 'nprogress/nprogress.css';
import 'bootstrap/dist/css/bootstrap.min.css';
import 'react-toastify/dist/ReactToastify.css';
import '@uppy/core/dist/style.css';
import '@uppy/drag-drop/dist/style.css';
import 'slick-carousel/slick/slick.css';
import 'slick-carousel/slick/slick-theme.css';
import 'react-datetime/css/react-datetime.css';
import 'cropperjs/dist/cropper.css';
import 'react-h5-audio-player/src/styles.scss';
import 'styles/globals.css';
import 'styles/scss/globals.scss';
import 'styles/scss/forms.scss';
import 'styles/scss/tables.scss';
import { LanguageProvider } from 'context/LanguageContext';
import LanguageContextHandler from 'components/LanguageContextHandler';
import { init as initializeSentry } from 'utils/sentry';
import { supportedLanguages, translate as t } from 'utils/helpers';
import { gtmEvents } from 'utils/gtm.ts';
import routes from 'utils/routes';
import { LoadingProvider } from 'context/LoadingContext';
import PlanLimitExceedPopup from 'components/Toast/PlanLimitExceedPopup';
import LoadingOverlay from '../components/LoadingOverlays';

initializeSentry();

NProgress.configure({ easing: 'ease', speed: 350 });
const stagingPrefix = 's_';
const isDevelopment = process.env.NEXT_PUBLIC_ENV !== 'production';

const MumblerApp = ({
  Component,
  pageProps,
  token,
  avatar,
  avatarWebp,
  isEmailVerified,
  playerData,
  lang,
  isMobile,
  err,
}) => {
  const logout = () => {
    toast.success(t(lang, 'common')('logoutForSecurityPurpose'));
    if (process.env.NEXT_PUBLIC_FRONTEND_URL === 'http://localhost:3000') {
      Cookies.remove('token', { path: '/' });
      Cookies.remove('podcaster', { path: '/' });
      Cookies.remove('avatar', { path: '/' });
      Cookies.remove('avatar_webp', { path: '/' });
      Cookies.remove('podcast_id');
      Cookies.remove('isEmailVerified', { path: '/' });
      Cookies.remove('playerData', { path: '/' });
      Cookies.remove('subscriptionConfirmation', { path: '/' });
      Cookies.remove('onBoarding', { path: '/' });
      Cookies.remove('creator_id', { path: '/' });
    } else {
      Cookies.remove('token', { path: '/', domain: '.mumbler.io' });
      Cookies.remove('podcaster', { path: '/', domain: '.mumbler.io' });
      Cookies.remove('avatar', { path: '/', domain: '.mumbler.io' });
      Cookies.remove('avatar_webp', { path: '/', domain: '.mumbler.io' });
      Cookies.remove('podcast_id');
      Cookies.remove('isEmailVerified', { path: '/', domain: '.mumbler.io' });
      Cookies.remove('playerData', { path: '/', domain: '.mumbler.io' });
      Cookies.remove('subscriptionConfirmation', {
        path: '/',
        domain: '.mumbler.io',
      });
      Cookies.remove('onBoarding', { path: '/', domain: '.mumbler.io' });
      Cookies.remove('creator_id', { path: '/', domain: '.mumbler.io' });
    }

    Router.push(routes.login(lang));
  };

  useEffect(() => {
    if (supportedLanguages.includes(lang)) {
      if (
        process.env.NEXT_PUBLIC_APP_FRONTEND_URL === 'http://localhost:3000'
      ) {
        Cookies.set('language', lang, { expires: 40, path: '/' });
      } else {
        Cookies.set('language', lang, {
          expires: 40,
          path: '/',
          domain: '.mumbler.io',
        });
      }
    }

    Router.events.on('routeChangeStart', () => {
      NProgress.start();

      const cookiesJson = document?.cookie?.split('; '); // Get all cookies as an object

      // Filter cookies with the specified name
      const matchingCookies = cookiesJson.filter((cookie) => {
        const [name] = cookie.split('=');
        return name === `${isDevelopment ? stagingPrefix : ''}podcaster`;
      });

      // Check if there are multiple cookies with the same name
      if (matchingCookies.length >= 2) {
        // set all cookies to past past to delete it

        document?.cookie?.split(';').forEach((c) => {
          document.cookie = c
            .replace(/^ +/, '')
            .replace(/=.*/, `=;expires=${new Date().toUTCString()};path=/`);
        });

        // clearing local storage
        localStorage.clear();
        // Router.push(routes.login(lang));
        // Log out the user (perform your logout logic here)
        logout();
      }
    });
    Router.events.on('routeChangeComplete', (url) => {
      NProgress.done();
      trackPages(url);
    });
    Router.events.on('routeChangeError', () => NProgress.done());
  }, []);

  const trackPages = (url) => {
    const uri = url;
    if (uri.includes('/reset-password') || uri.includes('/error')) {
      return;
    }
    const title = document?.title;
    gtmEvents.trackPage({ url, title });
  };

  const contextClass = {
    success: 'toast-success',
    // error: "bg-red-600",
    // info: "bg-gray-600",
    // warning: "bg-orange-400",
    default: '',
    // dark: "bg-white-600 font-gray-300",
  };

  return (
    <LoadingProvider>
      <LoadingOverlay />
      <LanguageProvider cookieLang={lang}>
        <UserProvider
          token={token}
          avatar={avatar}
          avatar_webp={avatarWebp}
          isEmailVerified={Boolean(isEmailVerified)}
          playerData={playerData}
          isMobile={isMobile}>
          <Toast
            message=""
            toastClassName={({ type }) => contextClass[type || 'default']}
          />
          <SuccessMessagePopup message="" />
          <PlanLimitExceedPopup />
          <ImportPodcastMessagePopup message="" />
          <CountrySelectionPopup />
          <Component {...pageProps} err={err} />
          <PodcastPlayer lang={lang} />
          <LanguageContextHandler />
        </UserProvider>
      </LanguageProvider>
    </LoadingProvider>
  );
};
MumblerApp.propTypes = {
  Component: PropTypes.any,
  pageProps: PropTypes.any,
  country: PropTypes.any,
  token: PropTypes.string,
  avatar: PropTypes.string,
  isEmailVerified: PropTypes.string,
  playerData: PropTypes.object,
  lang: PropTypes.oneOf(supportedLanguages),
  isMobile: PropTypes.bool,
  avatarWebp: PropTypes.string,
};

MumblerApp.getInitialProps = async (appContext) => {
  const appProps = await App.getInitialProps(appContext);
  const lang = supportedLanguages.includes(appContext.ctx.query['podcast-name'])
    ? appContext.ctx.query['podcast-name']
    : '';
  const isMobile = Boolean(
    (appContext.ctx.req
      ? appContext.ctx.req.headers['user-agent']
      : navigator.userAgent
    )?.match(
      /Android|BlackBerry|iPhone|iPad|iPod|Opera Mini|IEMobile|WPDesktop/i,
    ),
  );
  const { token, avatar, avatar_webp, isEmailVerified, playerData, language } =
    cookies(appContext.ctx);
  return {
    ...appProps,
    token,
    avatar,
    avatarWebp: avatar_webp,
    isEmailVerified,
    playerData,
    lang: lang || appProps?.pageProps?.lang || language || 'en',
    isMobile,
  };
};

export default MumblerApp;
